/* Global app layout */
.App {
    padding: 0;
    background-color: var(--background-color);
    min-height: 100vh;
}

/* Header */
.Header {
    border-bottom: 1px solid var(--light-blue);
    background-color: var(--background-color);
    font-family: var(--header-font);
    color: var(--text-color);
    padding: 20px;
    text-align: center;
    font-size: 24px;
}

/* Content */
.Content {
    margin-top: 20px;
    padding: 2px;
    font-family: var(--base-font);
}

/* Body */
.Body {
    background-color: var(--background-color);
    color: var(--text-color);
    font-size: 18px;
    padding: 2px;
}
/*.tiki-fullscreen {*/
/*    position: fixed;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100vw;*/
/*    height: 100vh;*/
/*    background-color: rgba(0, 0, 0, 0.8);*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    z-index: 1000;*/
/*    animation: tiki-zoom-in 0.5s ease-in-out;*/
/*}*/

@keyframes tiki-zoom-in {
    0% {
        transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/*!* Buttons *!*/
/*.btn {*/
/*    display: inline-block;*/
/*    background-color: var(--button-color);*/
/*    color: #fff;*/
/*    padding: 10px 20px;*/
/*    font-size: 16px;*/
/*    border: none;*/
/*    border-radius: 5px;*/
/*    cursor: pointer;*/
/*    transition: background-color 0.3s ease;*/
/*}*/

/*.btn:hover {*/
/*    background-color: var(--link-color);*/
/*}*/

/*.btn-outline {*/
/*    background-color: transparent;*/
/*    color: var(--button);*/
/*    border: 2px solid var(--button);*/
/*}*/

/*.btn-outline:hover {*/
/*    background-color: var(--button);*/
/*    color: #fff;*/
/*}*/

/* Links */
.terms-link {
    text-align: right;
    text-decoration: none;
    color: var(--text-color);
    background-color: var(--background-color);
}

.terms-link:hover {
    text-decoration: underline;
}

.privacy-policy-link {
    text-decoration: underline;
    color: var(--link-color);
}

.privacy-policy-link:hover {
    color: var(--text-color);
}

/* Form Inputs */
input, select, textarea {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid var(--light-blue);
    border-radius: 5px;
    font-size: 16px;
    color: var(--text-color);
}

input:focus, select:focus, textarea:focus {
    outline: none;
    border-color: var(--button);
    box-shadow: 0 0 5px var(--button);
}

/* Dropdowns */
.custom-dropdown .dropdown-menu,
.custom-dropdown-item {
    background-color: #fff;
    color: var(--text-color);
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
}

.custom-dropdown-item:hover {
    background-color: #f8f9fa;
    color: var(--link-color);
}

.dropdown-divider {
    border-top: 1px solid #e9ecef;
}

/* Navbar */
.navbar-nav .nav-link {
    color: #000;
    font-size: 18px;
}

.navbar-nav .nav-link:hover {
    color: var(--button);
}

/* Shaka Button Ripple Effect */
.shaka-button {
    position: relative;
}

.shaka-button.ripple::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    background: rgba(70, 130, 180, 0.4);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    animation: ripple 0.6s ease-out;
}

@keyframes ripple {
    to {
        transform: translate(-50%, -50%) scale(4);
        opacity: 0;
    }
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
    font-family: var(--header-font);
    color: var(--text-color);
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 26px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 18px;
}

/* Footer */
.Footer {
    text-align: center;
    padding: 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: var(--base-font);
    font-size: 14px;
}

.Footer a {
    color: var(--link-color);
    text-decoration: none;
}

.Footer a:hover {
    text-decoration: underline;
}
